import { Box, Button, HTMLChakraProps } from "@chakra-ui/react";
import {Link} from "react-router-dom";

export default function CustomButton({
  bg = "greenMid",
  color = "yellowLight",
  type,
  isRelative = true,
  isLoading = false,
  transformIt = false,
  transformItBy = "-50%",
  href = "#",
  children,
  onClick,
  full = false,
  ...props
}: HTMLChakraProps<"button"> &
  HTMLChakraProps<"div"> & {
    isRelative?: boolean;
    isLoading?: boolean;
    transformIt?: boolean;
    transformItBy?: string;
    onClick?: any;
    href?: string;
    full?: boolean;
  }) {
  if (href !== "#") {
    return (
      <Link to={href}>
        <Button
          bg="transparent"
          p="0"
          _hover={{}}
          _focus={{}}
          _active={{}}
          type={"button"}
          h="20"
          display={props.display || "unset"}
          alignSelf={props.alignSelf || "stretch"}
          position={isRelative ? "relative" : "unset"}
          justifyContent={{base: "center", md: "flex-start"}}
          flex={props?.flex ?? "unset"}
          width={full ? "100%" : "auto"}
        >
          <Box
            {...props}
            bg={bg}
            h="16"
            className="btn-wrapper"
            transform={transformIt ? `translateX(${transformItBy})` : "none"}
            borderRadius={"1.5rem"}
            position={"absolute"}
            bottom="0px"
            _hover={{
              bg: "greenDark",
              "& button": {
                borderColor: "greenExtraLight2",
              },
            }}
            _active={{
              height: "12",
              "& button": {
                borderColor: "greenExtraLight2",
              },
            }}
          >
            <Button
              as="a"
              href={href}
              textTransform={"uppercase"}
              borderRadius="1.5rem"
              border="3px solid"
              borderColor={bg}
              bg={"blackLight"}
              color={color}
              w="full"
              _hover={{}}
              _active={{}}
              _focus={{}}
              type={type || "button"}
              isLoading={isLoading}
              loadingText=""
              _loading={{ opacity: 1, color: "greenDark" }}
            >
              {children || "Text"}
            </Button>
          </Box>
        </Button>
      </Link>
    );
  }
  return (
    <Button
      bg="transparent"
      p="0"
      _hover={{}}
      _focus={{}}
      _active={{}}
      type={"submit"}
      h="20"
      display={props.display || "unset"}
      alignSelf={props.alignSelf || "stretch"}
      position={isRelative ? "relative" : "unset"}
      flex={props?.flex ?? "unset"}
      width={full ? "100%" : "auto"}
    >
      <Box
        {...props}
        bg={bg}
        h="16"
        className="btn-wrapper"
        transform={transformIt ? `translateX(${transformItBy})` : "none"}
        borderRadius={"1.5rem"}
        position={"absolute"}
        bottom="0px"
        _hover={{
          bg: "greenDark",
          "& button": {
            borderColor: "greenExtraLight2",
          },
        }}
        _active={{
          height: "12",
          "& button": {
            borderColor: "greenExtraLight2",
          },
        }}
      >
        <Button
        as="div"
            textTransform={"uppercase"}
            borderRadius="1.5rem"
            border="3px solid"
            borderColor={bg}
            bg={"blackLight"}
            color={color}
            w="full"
            _hover={{}}
            _active={{}}
            _focus={{}}
            type={"submit"}
            isLoading={isLoading}
            loadingText=""
            _loading={{ opacity: 1, color: "greenDark" }}
          >
            {children || "Text"}
          </Button>
      </Box>
    </Button>
  );
}
