import { useState, useEffect } from "react";

export const useScroll = () => {
  // Set a single object `{ x: ..., y: ..., direction: ... }` once on init
  const [scroll, setScroll] = useState({
    x:
      typeof window !== "undefined" &&
      window.document.body.getBoundingClientRect().left,
    y:
      typeof window !== "undefined" &&
      window.document.body.getBoundingClientRect().top,
    direction: "",
  });

  const listener = (e: any) => {
    if (typeof window !== "undefined") {
      setScroll((prev) => ({
        x: document.body.getBoundingClientRect().left,
        y: -document.body.getBoundingClientRect().top,
        // Here we’re comparing the previous state to the current state to get the scroll direction
        direction:
          prev.y > -document.body.getBoundingClientRect().top ? "up" : "down",
      }));
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", listener);
    }
    // cleanup function occurs on unmount
    return () => window.removeEventListener("scroll", listener);
    // Run `useEffect` only once on mount, so add `, []` after the closing curly brace }
  }, []);

  return scroll;
};
