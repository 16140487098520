import axiosInstance from "../utils/axiosInstance";

export const fetchFeedback = async (page: number, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/feedback?page=${page}&limit=20`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const fetchOneFeedback = async (id: string | undefined, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/feedback/${id}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const togglePublishFeedback = async ({id, token}: {id: string, token: string}) => {
  return await axiosInstance({
    method: "patch",
    url: `/feedback/${id}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};
