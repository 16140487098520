import {
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useFormState,
} from "react-hook-form";
import _ from "lodash";
import { IoAlertOutline } from "react-icons/io5";
import { ReactNode } from "react";

interface IInputField {
  name: string;
  label?: string;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  placeholder?: string;
  icon?: ReactNode;
  bg?: "darkGrey" | "light-blue-light";
  hidden?: boolean;
  disabled?: boolean;
  type: string;
  height?: string | undefined;
  options?: { id: number; value: string; label: string }[];
}

const NumberField = ({
  name,
  label,
  rules,
  placeholder,
  icon,
  bg,
  hidden = false,
  disabled = false,
  type = "text",
}: IInputField) => {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={_.get(watch(), name)}
        render={({ field: { ref, ...rest } }) => (
          <InputGroup width={"100%"} size="lg" variant={"primary"}>
            <NumberInput width={"full"} min={0}>
              <NumberInputField
                color="yellowLight"
                bg={"blackLight"}
                hidden={hidden}
                disabled={disabled}
                placeholder={placeholder}
                {...rest}
                fontWeight={"normal"}
                _placeholder={{ color: "yellowLight" }}
                border="1px solid"
                borderColor={
                  !hidden && _.get(errors, name)
                    ? "#eb5757"
                    : "greenExtraLight1"
                }
                rounded="full"
                width="100%"
              />
            </NumberInput>
            {!hidden && _.get(errors, name) ? (
              <InputLeftElement
                pointerEvents="none"
                placement="right"
                h={"full"}
              >
                <IoAlertOutline style={{ color: "#eb5757" }} />
              </InputLeftElement>
            ) : null}
          </InputGroup>
        )}
        rules={rules}
      />
    </div>
  );
};

export default NumberField;
