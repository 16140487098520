
export default function Unchecked() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9996 1.1532C9.43474 1.1559 6.97319 2.16437 5.14368 3.96203C3.31416 5.75969 2.2626 8.20314 2.21484 10.7676V13.9356C2.21484 16.5313 3.24599 19.0207 5.08145 20.8562C6.9169 22.6917 9.40631 23.7228 12.002 23.7228C14.5978 23.7228 17.0872 22.6917 18.9226 20.8562C20.7581 19.0207 21.7892 16.5313 21.7892 13.9356V10.9356C21.7835 8.34165 20.7501 5.85569 18.9152 4.02216C17.0803 2.18864 14.5936 1.157 11.9996 1.1532ZM11.9996 19.7748C10.2528 19.7748 8.54526 19.2568 7.09285 18.2863C5.64043 17.3159 4.50841 15.9365 3.83994 14.3227C3.17147 12.7088 2.99656 10.933 3.33735 9.21976C3.67813 7.50652 4.5193 5.93281 5.75448 4.69763C6.98965 3.46245 8.56336 2.62129 10.2766 2.2805C11.9898 1.93972 13.7657 2.11462 15.3795 2.78309C16.9933 3.45157 18.3727 4.58359 19.3432 6.036C20.3137 7.48842 20.8316 9.19599 20.8316 10.9428C20.8272 13.2832 19.895 15.5263 18.2395 17.1805C16.5839 18.8347 14.34 19.7651 11.9996 19.7676V19.7748Z"
        fill="#EAD3AB"
      />
    </svg>
  );
}
