import { CloseIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Text } from "@chakra-ui/react";

export default function Toast({ toast, toastIdRef, message, icon, status="success" }: any) {
  return (
    <HStack color={status === "error" ? "white" : "#5D714E"} p={3} bg={status === "error" ? "redLight" : "yellowLight"} justify={"space-between"} rounded="lg" align="center">
      <HStack align={"center"}>
        {icon}
        <Text fontWeight={"bold"} color={status === "error" ? "white" : "greenDark"}>{message}</Text>
      </HStack>
      <IconButton
        aria-label="close toast"
        icon={<CloseIcon />}
        onClick={() => toast.close(toastIdRef.current)}
        size="xs"
        bg={status === "error" ? "red.400" : "yellowLight"}
        _hover={{ bg: status === "error" ? "red.500" : "yellowDark" }}
        _active={{}}
      />
    </HStack>
  );
}
