import { useRef, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import {
  fetchFeedback,
  togglePublishFeedback,
} from "../services/feedback.services";
import {
  Box,
  Container,
  Button,
  Text,
  Grid,
  GridItem,
  ButtonGroup,
  Spinner,
  Stack,
  useToast,
  Tag,
} from "@chakra-ui/react";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import Loading from "../components/Loading";
import PageHeader from "../components/PageHeader";
import TableFooter from "../components/TableFooter";
import Dialog from "../components/DialogAlert";
import Toast from "../components/Toast";
import CheckIcon from "../assets/icons/CheckIcon";
import TableHeader from "../components/Table/TableHeader";

const columns = ["Name", "Date", "Rating", "Would recommend", "Actions"];

export interface IFeedback {
  id: string;
  name: string;
  email: string;
  created_at: string;
  comment: string;
  equipements: string;
  experience: string;
  isFeatured: boolean;
  rate: string;
  recommend: boolean;
  support: string;
}

function Feedback() {
  const { getAccessTokenSilently } = useAuth0();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );

  const { data, isLoading } = useQuery(["feedback", page], async () => {
    const token = await getAccessTokenSilently();
    return fetchFeedback(page, token);
  }, {
    refetchOnWindowFocus: false
  });

  return (
    <Box bg="blackLight" flex={1}>
      <PageHeader title="Received feedback" />
      <Container maxW={"7xl"} mt="6">
        <Grid
          gridTemplateColumns={`repeat(${columns.length}, minmax(135px, 1fr))`}
          gap="0"
          overflowX="auto"
          className="feedback-grid"
        >
          <TableHeader columns={columns} />
          {isLoading ? (
            <Stack align={"center"} justify="center" gridColumn={"1/6"} h="20">
              <Spinner color="yellowLight" />
            </Stack>
          ) : (
            <Grid
              gridTemplateColumns={`repeat(${columns.length}, minmax(135px, 1fr))`}
              gridColumn={"1/6"}
              gap="0"
              border="1px solid #96A090"
              borderRadius={"xl"}
              overflow="hidden"
            >
              {data?.items?.map((feedback: IFeedback, index: number) => (
                <TableRow
                  key={feedback.id}
                  feedback={feedback}
                  isLast={index === data.items.length - 1}
                />
              ))}
            </Grid>
          )}
        </Grid>
        <TableFooter
          currentPage={page}
          setPage={setPage}
          totalPages={data?.meta?.totalPages ?? "-"}
        />
      </Container>
    </Box>
  );
}

export default withAuthenticationRequired(Feedback, {
  onRedirecting: () => <Loading />,
  returnTo: "/",
});

const TableRow = ({
  feedback,
  isLast,
}: {
  feedback: IFeedback;
  isLast: boolean;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(togglePublishFeedback, {
    onSuccess: (res) => {
      const { isFeatured } = res;
      toastIdRef.current = toast({
        position: "top",
        duration: 2000,
        render: () => (
          <Toast
            toast={toast}
            message={isFeatured ? "Feedback published" : "Feadback unpublished"}
            toastIdRef={toastIdRef}
            icon={<CheckIcon />}
          />
        ),
      });
      queryClient.invalidateQueries("feedback");
    },
  });
  return (
    <>
      <GridItem
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
        display={"flex"}
        alignItems="center"
      >
        <Tag bg="yellowDark" mr="1" minWidth={"35px"} textAlign="center" alignItems={"center"} justifyContent="center">{feedback.isFeatured ? "⭐" : "✰"}</Tag>
        <Text color="yellowLight" textTransform={"uppercase"} noOfLines={1}>
          {feedback.name}
        </Text>
      </GridItem>
      <GridItem
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <Text color="yellowLight">
          {moment(feedback.created_at).format("DD/MM/YYYY")}
        </Text>
      </GridItem>
      <GridItem
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <ReactStars
          count={5}
          value={+feedback.rate}
          edit={false}
          size={20}
          isHalf={true}
          emptyIcon={<i className="fa fa-star"></i>}
          halfIcon={<i className="fas fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#EAD3AB"
          color="gray"
        />
      </GridItem>
      <GridItem
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <Text color={feedback.recommend ? "yellowLight" : "yellowDark"}>
          {feedback.recommend ? "Yes" : "No"}
        </Text>
      </GridItem>
      <GridItem
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        borderLeft={"1px solid #96A090"}
        px="2"
        py="4"
      >
        <ButtonGroup
          variant="link"
          h="full"
          w={"full"}
          justifyContent="space-around"
          alignItems={"center"}
        >
          <Dialog
            question="Are you sure?"
            description={
              feedback.isFeatured
                ? "This feedback will be hidden from the website's testimonials section."
                : "This feedback will be displayed in the website's testimonials section, it will include client name, comment, and the rating."
            }
            isLoading={isLoading}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              return mutateAsync({ id: feedback.id, token });
            }}
          >
            <Button
              color="yellowDark"
              textTransform={"uppercase"}
              fontSize={{ base: "xs", md: "sm" }}
              fontWeight={500}
              mr="2"
            >
              {feedback.isFeatured ? "Unpublish" : "Publish"}
            </Button>
          </Dialog>
          <Link to={`/feedback/${feedback.id}`}>
            <Button
              color="yellowDark"
              textTransform={"uppercase"}
              fontSize={{ base: "xs", md: "sm" }}
              fontWeight={500}
            >
              Details
            </Button>
          </Link>
        </ButtonGroup>
      </GridItem>
    </>
  );
};
