
export default function HorizontalLogo() {
    return (
      <svg
        width="152"
        height="16"
        viewBox="0 0 152 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M70.9423 0.162903H70.3979V15.4736H86.3863V11.8294H71.3838C71.2701 11.8294 71.1611 11.7842 71.0807 11.7037C71.0003 11.6232 70.9552 11.514 70.9552 11.4002L70.9423 0.162903Z"
          fill="#242A34"
        />
        <path
          d="M111.822 4.83295C111.426 5.7977 111.225 6.83112 111.228 7.87404C111.232 8.91696 111.441 9.94892 111.843 10.9109C113.412 14.6194 117.081 15.6324 120.639 15.6324C123.533 15.5664 126.4 15.0568 129.139 14.1215V10.4859C127.515 11.9067 124.527 12.8638 121.098 12.8638C115.954 12.8638 111.766 10.7177 111.766 8.04788C111.766 5.37806 115.946 3.23621 121.098 3.23621C124.527 3.23621 127.528 4.1934 129.144 5.61844V1.51499C129.011 1.46777 115.251 -3.50701 111.822 4.83295Z"
          fill="#242A34"
        />
        <path
          d="M134.913 0.184326V15.4821H151.004V12.3444H135.457V8.58007C135.457 8.46623 135.503 8.35705 135.583 8.27655C135.663 8.19606 135.772 8.15084 135.886 8.15084H150.987V7.60571H135.903C135.79 7.60571 135.681 7.5605 135.6 7.48C135.52 7.3995 135.475 7.29032 135.475 7.17648V3.35204H151.021V0.214362L134.913 0.184326Z"
          fill="#242A34"
        />
        <path
          d="M57.0199 0.132812L47.7012 15.4735H51.3446L53.7879 11.4473C53.8269 11.3814 53.8829 11.327 53.95 11.29C54.0171 11.2531 54.0928 11.2348 54.1694 11.237H64.1525L66.7244 15.4735H67.3588L58.0443 0.132812H57.0199ZM63.8225 10.6876H54.2508L59.0345 2.80693L63.8225 10.6876Z"
          fill="#242A34"
        />
        <path
          d="M98.7355 0.132812L89.4167 15.4735H93.0559L95.5035 11.4473C95.5425 11.3849 95.5969 11.3335 95.6614 11.2982C95.726 11.2628 95.7986 11.2447 95.8721 11.2456H105.86L108.431 15.4821H109.066L99.7471 0.141405L98.7355 0.132812ZM105.538 10.6876H95.9621L100.75 2.80692L105.538 10.6876Z"
          fill="#242A34"
        />
        <path
          d="M48.224 2.84984C48.224 2.50205 48.1556 2.15767 48.0227 1.83636C47.8897 1.51505 47.6949 1.22309 47.4493 0.977165C47.2038 0.731243 46.9122 0.536164 46.5913 0.403072C46.2705 0.26998 45.9266 0.201477 45.5792 0.201477H31.1982V15.4606H31.7426V9.6231C31.7426 9.50926 31.7878 9.40007 31.8682 9.31958C31.9486 9.23908 32.0576 9.19386 32.1713 9.19386H45.5621C46.2635 9.19386 46.9362 8.91484 47.4322 8.41818C47.9282 7.92151 48.2068 7.24789 48.2068 6.54551L48.224 2.84984ZM45.5792 8.63587H32.1884C32.0747 8.63587 31.9657 8.59064 31.8853 8.51015C31.8049 8.42965 31.7598 8.32048 31.7598 8.20664V4.3779H45.5921C46.1062 4.44743 46.5776 4.70129 46.9189 5.0924C47.2603 5.4835 47.4484 5.9853 47.4484 6.50473C47.4484 7.02416 47.2603 7.52597 46.9189 7.91707C46.5776 8.30818 46.1062 8.56204 45.5921 8.63157L45.5792 8.63587Z"
          fill="#242A34"
        />
        <path
          d="M17.9364 12.4247V7.83725C17.9378 7.32628 17.8197 6.82209 17.5915 6.36506C17.3633 5.90804 17.0314 5.51089 16.6223 5.20545H23.3707V0H8.72681C7.85358 0 7.01612 0.347364 6.39865 0.965676C5.78119 1.58399 5.43429 2.4226 5.43429 3.29702V7.8845C5.43287 8.39547 5.551 8.89968 5.7792 9.3567C6.0074 9.81372 6.33933 10.2109 6.74839 10.5163H0V15.7254H14.6367C15.0699 15.7264 15.4992 15.6417 15.8997 15.4762C16.3003 15.3107 16.6642 15.0676 16.9708 14.761C17.2773 14.4544 17.5204 14.0902 17.6861 13.6893C17.8519 13.2883 17.9369 12.8586 17.9364 12.4247ZM8.72681 10.5236C8.04969 10.5109 7.4032 10.2387 6.92043 9.76311C6.43766 9.2875 6.15535 8.64465 6.13162 7.9669C6.1079 7.28914 6.34456 6.62808 6.79289 6.11979C7.24123 5.6115 7.8671 5.29467 8.54167 5.23453H14.6367C15.3138 5.24723 15.9603 5.5194 16.443 5.99501C16.9258 6.47062 17.2081 7.11347 17.2318 7.79122C17.2556 8.46897 17.0189 9.13004 16.5706 9.63833C16.1222 10.1466 15.4964 10.4634 14.8218 10.5236H8.72681Z"
          fill="#EAD3AB"
        />
      </svg>
    );
  }
  