import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Container,
  FormLabel,
  Image,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { pressReleasesFields } from "../../utils/fields";
import Field from "../Form";
import PageHeader from "../PageHeader";
import EditorContainer from "../Editor";
import { CheckIcon } from "@chakra-ui/icons";
import UploadImages from "./UploadImages";
import { useMutation } from "react-query";
import { createNewArticle, uploadFile } from "../../services/newsroom.services";
import moment from "moment";
import Toast from "../Toast";
import { RiErrorWarningLine } from "react-icons/ri";
import axiosInstance from "../../utils/axiosInstance";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

export default function PressReleasesForm() {
  const [editorSate, setEditorSate] = useState(EditorState.createEmpty());
  const [images, setImages] = useState<Array<any>>([]);
  const [thumbnailImg, setThumbnailImg] = useState({
    preview: "",
    url: "",
    file: "",
    isCompleted: false,
    isLoading: false,
  });
  const methods = useForm({
    defaultValues: {
      lang: "en",
      title: "",
      subTitle: "",
      thumbnail: "",
      readTime: 1,
      createdAt: moment(new Date()).format("YYYY-MM-DD"),
    },
  });
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleThumbnail = (e: any) => {
    const preview = URL.createObjectURL(e.target.files[0]);
    setThumbnailImg({
      ...thumbnailImg,
      preview: preview,
      file: e.target.files[0],
    });
  };

  const uploadThumbnail = async () => {
    setThumbnailImg({ ...thumbnailImg, isLoading: true });
    const token = await getAccessTokenSilently();
    let fd = new FormData();
    fd.append("file", thumbnailImg.file);

    try {
      const response = await axiosInstance({
        method: "post",
        url: `/media`,
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });

      setThumbnailImg({
        ...thumbnailImg,
        url: response.data.url,
        isLoading: false,
        isCompleted: true,
      });
    } catch (error) {
      setThumbnailImg({ ...thumbnailImg, isLoading: false });
    }
  };

  const handleImage = (e: any, imageId: number) => {
    const preview = URL.createObjectURL(e.target.files[0]);
    const _images = images.map((el: any) =>
      el.id === imageId
        ? { ...el, preview: preview, file: e.target.files[0] }
        : el
    );
    setImages(_images);
  };

  const { mutateAsync } = useMutation(uploadFile, {
    onSuccess: (res) =>
      setImages(
        images.map((el: any) =>
          el.id === res.imageId
            ? { ...el, isLoading: false, isCompleted: true, imgUrl: res.url }
            : el
        )
      ),
  });

  const { mutate: createArticle, isLoading: creatingArticle } = useMutation(
    createNewArticle,
    {
      onSuccess: () => {
        toastIdRef.current = toast({
          position: "top",
          duration: 2000,
          render: () => (
            <Toast
              toast={toast}
              message={"Article created"}
              toastIdRef={toastIdRef}
              icon={<CheckIcon />}
            />
          ),
        });
        navigate("/newsroom?tab=palace-in-the-press");
      },
    }
  );

  const readingTime = (text: string) => {
    const wpm = 225;
    const words = text.replace( /(<([^>]+)>)/ig, '').trim().split(/\s+/).length
    const time = Math.ceil(words/wpm)
    methods.setValue("readTime", time)
    return time
  }

  const onSubmit = async (data: any, options: { isPublished: boolean }) => {
    const imgUrls = images.map((image: any) => image.imgUrl);
    const token = await getAccessTokenSilently();
    const rawContentState = convertToRaw(editorSate.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    if (
      (images.length !== 0 &&
        images.some((image: any) => !image.isCompleted)) ||
      !thumbnailImg.isCompleted
    ) {
      toastIdRef.current = toast({
        position: "top",
        duration: 2000,
        render: () => (
          <Toast
            toast={toast}
            message={"Please upload all images"}
            toastIdRef={toastIdRef}
            icon={<RiErrorWarningLine />}
            status="error"
          />
        ),
      });
    } else {
      createArticle({
        ...data,
        readTime: readingTime(markup),
        thumbnail: thumbnailImg.url,
        content: markup,
        images: imgUrls,
        isPublished: options.isPublished,
        token,
      });
    }
  };

  return (
    <Box bg="blackLight" flex={1}>
      <PageHeader title="Newsroom" goBackUrl={`/newsroom?tab=press-releases`} />
      <Container maxW={"7xl"} mt="6">
        <Stack
          as="form"
          spacing={4}
          onSubmit={methods.handleSubmit((x: any) =>
            onSubmit(x, { isPublished: true })
          )}
        >
          <Stack spacing={-1}>
            <FormLabel color="yellowDark">Created at</FormLabel>
            <Input
              name="createdAt"
              type={"date"}
              color="yellowDark"
              borderColor={"greenExtraLight1"}
              rounded="full"
              defaultValue={methods.getValues("createdAt")}
              onChange={(x: any) =>
                methods.setValue(
                  "createdAt",
                  moment(x.target.value).format("DD/MM/YYYY")
                )
              }
            />
          </Stack>
          <FormProvider {...methods}>
            {pressReleasesFields.map((field: any) => (
              <Stack spacing={-1} key={field.name}>
                <FormLabel color="yellowDark">{field.placeholder}</FormLabel>
                {field.type === "select" ? (
                  <Field {...field} value={methods.getValues("lang")} />
                ) : (
                  <Field {...field} />
                )}
              </Stack>
            ))}
          </FormProvider>
          <Stack className="editor" spacing={-1}>
            <FormLabel color="yellowDark">Content</FormLabel>
            <EditorContainer
              setEditorSate={setEditorSate}
              editorSate={editorSate}
            />
          </Stack>
          <Stack direction={{ base: "column", md: "row" }}>
            <Stack align={"flex-start"} alignContent="flex-start">
              <Stack spacing={-1}>
                <FormLabel color="yellowDark">Thumbnail</FormLabel>
                <Input
                  type="file"
                  onChange={handleThumbnail}
                  color="yellowDark"
                  borderColor={"greenExtraLight1"}
                  rounded="full"
                />
              </Stack>
              <Button
                rounded="full"
                bg="blackLight"
                border="1px solid"
                borderColor={"greenExtraLight1"}
                color="yellowLight"
                _hover={{ bg: "greenDark", borderColor: "greenDark" }}
                onClick={uploadThumbnail}
              >
                Upload thumbnail
              </Button>
            </Stack>

            {thumbnailImg.preview && (
              <Box position={"relative"} flex={1}>
                {thumbnailImg.isCompleted && (
                  <Box
                    position={"absolute"}
                    top="50%"
                    left="50%"
                    transform={"translate(-50%,-50%)"}
                    zIndex={2}
                  >
                    <CheckIcon color="white" />
                  </Box>
                )}
                <Image
                  src={thumbnailImg.preview}
                  // maxW="100px"
                  // maxH={"50px"}
                  // opacity={0.4}
                  _hover={{ opacity: 1 }}
                  rounded={"5px"}
                />
              </Box>
            )}
          </Stack>
          <UploadImages
            images={images}
            setImages={setImages}
            handleImage={handleImage}
            mutateAsync={mutateAsync}
          />
          <Stack
            direction={{ base: "row", md: "row" }}
            alignSelf={{ base: "center", md: "flex-end" }}
          >
            <Button
              alignSelf={"flex-end"}
              px="6"
              variant={"ghost"}
              border="1px solid"
              borderColor="blackLight"
              color="yellowLight"
              rounded="full"
              _hover={{ bg: "blackAlpha.400" }}
              onClick={() =>
                onSubmit(methods.getValues(), { isPublished: false })
              }
              isLoading={creatingArticle}
            >
              Save in draft
            </Button>
            <Button
              type="submit"
              px="6"
              variant={"outline"}
              borderColor="greenExtraLight1"
              color="yellowLight"
              rounded="full"
              _hover={{ bg: "greenDark" }}
              isLoading={creatingArticle}
            >
              Publish
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
