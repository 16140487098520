export default function Bell() {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01 20.01C7.01 21.11 7.9 22 9 22C10.1 22 10.99 21.11 10.99 20.01H7.01ZM9 5C11.76 5 14 7.24 14 10V17H4V10C4 7.24 6.24 5 9 5ZM9 0.5C8.17 0.5 7.5 1.17 7.5 2V3.17C4.36 3.85 2 6.65 2 10V16L0 18V19H18V18L16 16V10C16 6.65 13.64 3.85 10.5 3.17V2C10.5 1.17 9.83 0.5 9 0.5Z"
        fill="#242A34"
      />
    </svg>
  );
}
