import { useRef, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  Text,
  Grid,
  GridItem,
  Spinner,
  Stack,
  ButtonGroup,
  useToast,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
// @ts-ignore
import moment from "moment";
import Loading from "../components/Loading";
import PageHeader from "../components/PageHeader";
import TableFooter from "../components/TableFooter";
import TableHeader from "../components/Table/TableHeader";
import { VscMailRead, VscMail, VscArrowRight } from "react-icons/vsc";
import { fetchContact, setContactAsSeen } from "../services/contact.services";
import Toast from "../components/Toast";
import CheckIcon from "../assets/icons/CheckIcon";

const columns = ["Name", "Email", "Subject", "Date", "Actions"];

export interface IContact {
  id: string;
  name: string;
  email: string;
  subject: string;
  message: string;
  date: string;
  seen: boolean;
  created_at: string;
}

function Contact() {
  const { getAccessTokenSilently } = useAuth0();

  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );

  const { data, isLoading } = useQuery(
    ["contact", page],
    async () => {
      const token = await getAccessTokenSilently();
      return fetchContact(page, token);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Box bg="blackLight" flex={1}>
      <PageHeader title="Received contact" />
      <Container maxW={"7xl"} mt="6">
        <Grid
          gridTemplateColumns={`repeat(${
            columns.length - 1
          }, minmax(135px, 1fr)) 120px`}
          gap="0"
          overflowX="auto"
          className="feedback-grid"
        >
          <TableHeader columns={columns} />
          {isLoading ? (
            <Stack align={"center"} justify="center" gridColumn={"1/6"} h="20">
              <Spinner color="yellowLight" />
            </Stack>
          ) : (
            <Grid
              gridTemplateColumns={`repeat(${
                columns.length - 1
              }, minmax(135px, 1fr)) 120px`}
              gridColumn={"1/6"}
              gap="0"
              border="1px solid #96A090"
              borderRadius={"xl"}
              overflow="hidden"
            >
              {data?.items?.map((contact: IContact, index: number) => (
                <TableRow
                  key={contact.id}
                  contact={contact}
                  isLast={index === data.items.length - 1}
                />
              ))}
            </Grid>
          )}
        </Grid>
        <TableFooter
          currentPage={page}
          setPage={setPage}
          totalPages={data?.meta?.totalPages ?? "-"}
        />
      </Container>
    </Box>
  );
}

export default withAuthenticationRequired(Contact, {
  onRedirecting: () => <Loading />,
  returnTo: "/",
});

const TableRow = ({
  contact,
  isLast,
}: {
  contact: IContact;
  isLast: boolean;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(setContactAsSeen, {
    onSuccess: (res) => {
      const { seen } = res;
      toastIdRef.current = toast({
        position: "top",
        duration: 2000,
        render: () => (
          <Toast
            toast={toast}
            message={
              seen ? "Message marked as read" : "Message marked as unread"
            }
            toastIdRef={toastIdRef}
            icon={<CheckIcon />}
          />
        ),
      });
      queryClient.invalidateQueries("contact");
    },
  });

  return (
    <>
      <GridItem
        my="auto"
        bg={contact.seen ? "transparent" : "#141a22"}
        fontWeight={contact.seen ? "normal" : "bold"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <Text color="yellowLight" textTransform={"uppercase"} noOfLines={1}>
          {contact.name}
        </Text>
      </GridItem>
      <GridItem
        my="auto"
        bg={contact.seen ? "transparent" : "#141a22"}
        fontWeight={contact.seen ? "normal" : "bold"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <Text color="yellowLight" noOfLines={1}>
          {contact.email}
        </Text>
      </GridItem>
      <GridItem
        my="auto"
        bg={contact.seen ? "transparent" : "#141a22"}
        fontWeight={contact.seen ? "normal" : "bold"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <Text color="yellowLight" noOfLines={1}>
          {contact.subject}
        </Text>
      </GridItem>
      <GridItem
        my="auto"
        bg={contact.seen ? "transparent" : "#141a22"}
        fontWeight={contact.seen ? "normal" : "bold"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="4"
      >
        <Text color="yellowLight">
          {moment(contact.created_at).format("DD/MM/YYYY")}
        </Text>
      </GridItem>
      <GridItem
        bg={contact.seen ? "transparent" : "#141a22"}
        fontWeight={contact.seen ? "normal" : "bold"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        borderLeft={"1px solid #96A090"}
        px="2"
        py="4"
      >
        <ButtonGroup
          variant="link"
          h="full"
          w={"full"}
          justifyContent="space-around"
          alignItems={"center"}
        >
          <Tooltip
            hasArrow
            label={contact.seen ? "Mark as unread" : "Mark as read"}
          >
            <IconButton
              _hover={{ bg: "blackAlpha.400" }}
              _active={{ bg: "blackAlpha.400" }}
              icon={
                contact.seen ? (
                  <VscMail fontSize={"1.4rem"} />
                ) : (
                  <VscMailRead fontSize={"1.4rem"} />
                )
              }
              color="yellowDark"
              aria-label={contact.seen ? "Mark as unread" : "Mark as read"}
              isLoading={isLoading}
              onClick={async () => {
                const token = await getAccessTokenSilently();
                return mutateAsync({ id: contact.id, token });
              }}
            />
          </Tooltip>
          {/* <Button
            color="yellowDark"
            textTransform={"uppercase"}
            fontSize={{ base: "xs", md: "sm" }}
            fontWeight={500}
            isLoading={isLoading}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              return mutateAsync({ id: contact.id, token });
            }}
          >
            Mark as read
          </Button> */}
          <Tooltip hasArrow label="Details" placement="left">
            <Link
              to={`/contact/${contact.id}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                _hover={{ bg: "blackAlpha.400" }}
                _active={{ bg: "blackAlpha.400" }}
                color="yellowDark"
                aria-label="See details"
                icon={<VscArrowRight fontSize={"1.4rem"} />}
              />
            </Link>
          </Tooltip>
        </ButtonGroup>
      </GridItem>
    </>
  );
};
