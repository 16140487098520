import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PalaceInThePressForm from "../components/NewsroomForms/PalaceInThePressForm";
import PressReleasesForm from "../components/NewsroomForms/PressReleasesForm";
import Loading from "../components/Loading";
import EditorContainer from "../components/Editor";
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { useMutation, useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchOnePressRelease,
  updatePressRelease,
} from "../services/newsroom.services";
import PageHeader from "../components/PageHeader";
import {
  Box,
  Button,
  Container,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { pressReleasesFields } from "../utils/fields";
import Field from "../components/Form";
import draftToHtml from "draftjs-to-html";
import Toast from "../components/Toast";
import CheckIcon from "../assets/icons/CheckIcon";

function transformDateFormat(inputDate: string) {
  // Split the input date string by '/'
  const dateComponents = inputDate.split("/");

  // Create a new Date object using the components
  const originalDate = new Date(
    `${dateComponents[1]}/${dateComponents[0]}/${dateComponents[2]}`
  );

  // Extract year, month, and day components
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const day = originalDate.getDate().toString().padStart(2, "0");

  // Create the transformed date string in the desired format
  const transformedDate = `${year}-${month}-${day}`;

  return transformedDate;
}

export default function UpdateNewsroom() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    [`article`, id],
    async () => {
      const token = await getAccessTokenSilently();
      return fetchOnePressRelease(Number(id), token);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const methods = useForm();

  useEffect(() => {
    if (data) {
      // Iterate through the keys in your data and set default values
      Object.keys(data).forEach((key: any) => {
        if(key !== "createdAt"){
          methods.setValue(key, data[key]);
        }
      });
    }
  }, [data, methods.setValue]);

  const readingTime = (text: string) => {
    const wpm = 225;
    const words = text
      .replace(/(<([^>]+)>)/gi, "")
      .trim()
      .split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    methods.setValue("readTime", time);
    return time;
  };

  const { mutate: updateArticle, isLoading: updatingArticle } = useMutation(
    updatePressRelease,
    {
      onSuccess: () => {
        toastIdRef.current = toast({
          position: "top",
          duration: 2000,
          render: () => (
            <Toast
              toast={toast}
              message={"Article created"}
              toastIdRef={toastIdRef}
              icon={<CheckIcon />}
            />
          ),
        });
        navigate("/newsroom?tab=press-releases");
      },
    }
  );

  const handleSubmit = async (payload: any) => {
    const token = await getAccessTokenSilently();
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    updateArticle({
      data:{
        ...payload,
        readTime: readingTime(markup),
        content: markup,
      },
      id,
      token,
      // thumbnail: thumbnailImg.url,
      // images: imgUrls,
      // isPublished: options.isPublished,
    });
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box bg="blackLight" flex={1}>
      <PageHeader
        title={data?.title}
        goBackUrl={`/newsroom?tab=press-releases`}
      />
      <Container maxW={"7xl"} my="6">
        <Stack
          as="form"
          spacing={4}
          onSubmit={methods.handleSubmit((x: any) => handleSubmit(x))}
        >
          <FormProvider {...methods}>
            {pressReleasesFields.map((field: any) => (
              <Stack spacing={-1} key={field.name}>
                <FormLabel color="yellowDark">{field.placeholder}</FormLabel>
                {field.type === "select" ? (
                  <Field {...field} value={methods.getValues("lang")} />
                ) : (
                  <Field {...field} />
                )}
              </Stack>
            ))}
          </FormProvider>
          <Stack className="editor" spacing={-1}>
            <FormLabel color="yellowDark">Content</FormLabel>
            <MyEditor
              initialContent={data?.content}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Stack>
          <Stack
            direction={{ base: "row", md: "row" }}
            alignSelf={{ base: "center", md: "flex-end" }}
          >
            <Button
              onClick={() => navigate(-1)}
              px="6"
              variant={"ghost"}
              borderColor="greenExtraLight1"
              color="yellowLight"
              rounded="full"
              _hover={{ bg: "greenDark" }}
              isLoading={updatingArticle}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              px="6"
              variant={"outline"}
              borderColor="greenExtraLight1"
              color="yellowLight"
              rounded="full"
              _hover={{ bg: "greenDark" }}
              isLoading={updatingArticle}
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

const MyEditor = ({ initialContent, editorState, setEditorState }: any) => {
  const onChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    if (initialContent) {
      const blocksFromHTML = convertFromHTML(initialContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialContent]);

  return (
    <EditorContainer setEditorSate={setEditorState} editorSate={editorState} />
  );
};
