import { ReactNode } from "react";
import { Box, Heading, Container, Stack, IconButton, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useScroll } from "../../utils/useScroll";
import ArrowLeftSmall from "../../assets/icons/ArrowLeftSmall";

export default function PageHeader({
  title,
  children,
  goBackUrl=""
}: {
  title: string;
  children?: ReactNode;
  goBackUrl?: string
}) {
  const scroll = useScroll();

  return (
    <Box
      bg={"greenMid"}
      py="6"
      px={0}
      position={"sticky"}
      top="0px"
      zIndex={2}
    >
      <Container maxW="7xl">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={{ base: "flex-start", md: "center" }}
        >
          <HStack spacing={4} align={{base: "baseline", md: "center"}}>
            {goBackUrl && <Stack
              bg="greenMid"
              justify={"center"}
              align="center"
              spacing={0}
              minW="35px"
            >
              <Link to={goBackUrl}>
                <IconButton
                  icon={<ArrowLeftSmall />}
                  _hover={{}}
                  _focus={{}}
                  aria-label="go back"
                  size="xs"
                  bg="transparent"
                />
              </Link>
            </Stack>}
            <Heading
              as="h1"
              color="yellowLight"
              fontFamily={"HKGroteskWide"}
              textTransform="uppercase"
              fontSize={scroll?.y >= 64 ? "xl" : "3xl"}
              transition="all 0.3s ease-in-out"
            >
              {title}
            </Heading>
          </HStack>
          {children}
        </Stack>
      </Container>
    </Box>
  );
}
