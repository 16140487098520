import { Divider, HStack, IconButton, Text } from "@chakra-ui/react";
import { createSearchParams, useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import ArrowRight from "../../assets/icons/ArrowRight";

interface IFooter {
  totalPages: number;
  currentPage: number;
  setPage: (page: any) => void;
}
export default function TableFooter({
  totalPages,
  currentPage,
  setPage,
}: IFooter) {
  const navigate = useNavigate();

  return (
      <HStack spacing={2} mt="6" justify={"flex-end"} mb="12">
        <IconButton
          zIndex={2}
          aria-label="next-btn"
          icon={<ArrowLeft />}
          onClick={() => {
            navigate({
              search: createSearchParams({
                page: String(currentPage - 1),
              }).toString(),
            });
            setPage((p: number) => p - 1);
          }}
          fontSize={"lg"}
          _hover={{}}
          _active={{}}
          _focus={{ outline: "none" }}
          size={"sm"}
          rounded="full"
          bg="transparent"
          disabled={currentPage === 1}
        />
        <Text color="yellowLight">Page {currentPage + "/" + totalPages}</Text>
        <IconButton
          zIndex={2}
          aria-label="next-btn"
          icon={<ArrowRight />}
          onClick={() => {
            navigate({
              search: createSearchParams({
                page: String(currentPage + 1),
              }).toString(),
            });
            setPage((p: number) => p + 1);
          }}
          fontSize={"lg"}
          _hover={{}}
          _active={{}}
          rounded="full"
          bg="transparent"
          _focus={{ outline: "none" }}
          size={"sm"}
          disabled={totalPages === currentPage}
        />
      </HStack>
    // <Button
    // onClick={() => {
    //   navigate({
    //     search: createSearchParams({
    //       page: String(currentPage + 1),
    //     }).toString(),
    //   });
    //   setPage((p: number) => p + 1);
    // }}
    //   disabled={totalPages <= currentPage}
    // >
    //   Next
    // </Button>
  );
}
