export default function ArrowRight() {
  return (
    <svg
      width="48"
      height="55"
      viewBox="0 0 48 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23.5" cy="24.125" r="22.5" fill="#242A34" />
      <path
        d="M23.9941 0.125C17.7045 0.131543 11.6683 2.57793 7.18203 6.93875C2.69572 11.2996 0.117098 17.227 0 23.4479V31.1329C0 37.4297 2.52856 43.4686 7.02944 47.9211C11.5303 52.3736 17.6348 54.875 24 54.875C30.3652 54.875 36.4697 52.3736 40.9706 47.9211C45.4714 43.4686 48 37.4297 48 31.1329V23.8554C47.986 17.563 45.4517 11.5324 40.9523 7.08462C36.4529 2.6368 30.355 0.134227 23.9941 0.125ZM23.9941 45.2978C19.7106 45.2978 15.5233 44.0413 11.9618 41.6871C8.40017 39.3329 5.62425 35.9868 3.98503 32.0719C2.34581 28.157 1.91692 23.8491 2.75259 19.6931C3.58825 15.5371 5.65095 11.7195 8.67983 8.72319C11.7087 5.72686 15.5677 3.68633 19.7689 2.85965C23.9701 2.03296 28.3247 2.45725 32.2822 4.07885C36.2396 5.70045 39.622 8.44654 42.0018 11.9698C44.3816 15.4932 45.6518 19.6355 45.6518 23.8729C45.6409 29.5502 43.355 34.9917 39.2953 39.0045C35.2355 43.0174 29.7331 45.2742 23.9941 45.2804V45.2978Z"
        fill="#EAD3AB"
      />
      <path
        d="M20.5963 15L30 23.9979L20.5963 33L19 31.147L26.4627 23.9979L19 16.8572L20.5963 15Z"
        fill="#EAD3AB"
      />
    </svg>
  );
}
