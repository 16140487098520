import { Routes, Route, useLocation } from "react-router-dom";
import HomeContainer from "./containers/HomeContainer";
import Navbar from "./components/Navbar";
import PageNotFound from "./pages/NotFound";
import Feedback from "./pages/Feedback";
import { Stack } from "@chakra-ui/react";
import FeedbackDetails from "./pages/FeedbackDetails";
import Contact from "./pages/Contact";
import ContactDetails from "./pages/ContactDetails";
import Newsroom from "./pages/Newsroom";
import CreateNewsroom from "./pages/CreateNewsroom";
import UpdateNewsroom from "./pages/UpdateNewsroom";

function App() {
  const { pathname } = useLocation();
  return (
    <Stack spacing={0} minH={"100vh"}>
      {pathname !== "/" && <Navbar />}
      <Routes>
        <Route path="/">
          <Route index element={<HomeContainer />} />
          <Route path="feedback">
            <Route index element={<Feedback />} />
            <Route path=":id" element={<FeedbackDetails />} />
          </Route>
          <Route path="contact">
            <Route index element={<Contact />} />
            <Route path=":id" element={<ContactDetails />} />
          </Route>
          <Route path="newsroom">
            <Route index element={<Newsroom />} />
            <Route path="create" element={<CreateNewsroom />} />
            <Route path="update/press-release/:id" element={<UpdateNewsroom />} />
            {/* <Route path=":id" element={<ContactDetails />} /> */}
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Stack>
  );
}

export default App;
