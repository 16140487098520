import { Box, Container, Heading, Stack, Text } from "@chakra-ui/react";
import Square from "../assets/icons/Square";
import CustomButton from "../components/Button";

export default function PageNotFound() {
  return (
    <Stack
      as="section"
      pt={{ base: "20", md: "20", "2xl": "36" }}
      bg="blackLight"
      spacing={{ base: 10, "2xl": 16 }}
      align="center"
      position={"relative"}
      minH="80vh"
    >
      <Box position={"absolute"} top="50px" right="0px">
        <Square />
      </Box>
      <Box
        position={"absolute"}
        top="50%"
        left="0px"
        transform={"rotate(180deg)"}
      >
        <Square />
      </Box>
      <Container maxW={"6xl"} position="relative">
        <Stack align={"center"} spacing={4}>
          <Stack spacing={0}>
            <Heading
              textTransform={"uppercase"}
              fontSize={{ base: "5xl", md: "8xl", "2xl": "9xl" }}
              color={"yellowLight"}
              fontWeight="light"
              textAlign={"center"}
            >
              404
            </Heading>
            <Heading
              fontFamily="HKGroteskWide, sans-serif"
              color="yellowLight"
              textAlign={"center"}
              fontSize={{ base: "4xl", md: "6xl" }}
            >
              Page not found
            </Heading>
          </Stack>
          <Text textAlign={"center"} color="yellowDark" maxW={"4xl"}>
            The page you requested doesn't exist. You may have mistyped the
            address, or It may have been deleted or moved to a new one.
          </Text>
          <CustomButton
            textAlign={"center"}
            alignSelf={"center"}
            transformIt
            width={"250px"}
            isRelative={true}
            href={"/"}
          >
            go back home
          </CustomButton>
        </Stack>
      </Container>
      <Container maxW={"6xl"}></Container>
    </Stack>
  );
}
