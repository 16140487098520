export default function LogoutIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 0H7.875V11.1765H10.125V0ZM14.13 2.72706L12.4987 4.34765C14.445 5.52118 15.75 7.63353 15.75 10.0588C15.75 13.7582 12.7238 16.7647 9 16.7647C5.27625 16.7647 2.25 13.7582 2.25 10.0588C2.25 7.63353 3.555 5.52118 5.49 4.33647L3.87 2.72706C1.53 4.33647 0 7.01882 0 10.0588C0 14.9988 4.0275 19 9 19C13.9725 19 18 14.9988 18 10.0588C18 7.01882 16.47 4.33647 14.13 2.72706Z"
        fill="#242A34"
      />
    </svg>
  );
}
