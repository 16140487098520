
export default function Checked() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0048 17.7877C15.7876 17.7877 18.8543 14.7211 18.8543 10.9382C18.8543 7.15537 15.7876 4.08875 12.0048 4.08875C8.2219 4.08875 5.15527 7.15537 5.15527 10.9382C5.15527 14.7211 8.2219 17.7877 12.0048 17.7877Z"
        fill="#EAD3AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14368 3.96203C6.97319 2.16437 9.43474 1.1559 11.9996 1.1532C14.5936 1.157 17.0803 2.18864 18.9152 4.02216C20.7501 5.85569 21.7835 8.34165 21.7892 10.9356V13.9356C21.7892 16.5313 20.7581 19.0207 18.9226 20.8562C17.0872 22.6917 14.5978 23.7228 12.002 23.7228C9.40631 23.7228 6.9169 22.6917 5.08145 20.8562C3.24599 19.0207 2.21484 16.5313 2.21484 13.9356V10.7676C2.2626 8.20314 3.31416 5.75969 5.14368 3.96203ZM7.09285 18.2863C8.54526 19.2568 10.2528 19.7748 11.9996 19.7748V19.7676C14.34 19.7651 16.5839 18.8347 18.2395 17.1805C19.895 15.5263 20.8272 13.2832 20.8316 10.9428C20.8316 9.19599 20.3137 7.48842 19.3432 6.036C18.3727 4.58359 16.9933 3.45157 15.3795 2.78309C13.7657 2.11462 11.9898 1.93972 10.2766 2.2805C8.56336 2.62129 6.98965 3.46245 5.75448 4.69763C4.5193 5.93281 3.67813 7.50652 3.33735 9.21976C2.99656 10.933 3.17147 12.7088 3.83994 14.3227C4.50841 15.9365 5.64043 17.3159 7.09285 18.2863Z"
        fill="#EAD3AB"
      />
    </svg>
  );
}
