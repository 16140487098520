import { extendTheme } from "@chakra-ui/react";

const colors = {
  greenExtraLight1: "#96A090",
  greenExtraLight2: "#96A190",
  greenLight: "#77866E",
  greenMid: "#77866E",
  greenDark: "#5D714E",
  blackLight: "#242A34",
  yellowMid: "#BCAC84",
  yellowDark: "#967F5B",
  yellowLight: "#EAD3AB",
  beige: "#EAE5D8",
  redLight: "#EB5757"
};

const fonts = {
  heading: "HKGroteskWide, sans-serif",
  body: "HKGroteskWide, sans-serif",
};

export const theme = extendTheme({ colors, fonts, components: { Text: { baseStyle: { color :"#EAD3AB"}}} });
