
export default function Square() {
  return (
    <svg
      width="281"
      height="207"
      viewBox="0 0 281 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M1 0.5H0.5V1V190C0.5 199.113 7.8873 206.5 17 206.5H281H281.5V206V1V0.5H281H1Z"
        fill="#242A34"
        stroke="#5D714E"
      />
    </svg>
  );
}
