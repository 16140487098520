import {
  Box,
  Container,
  Heading,
  HStack,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdPostAdd } from "react-icons/md";
import PageHeader from "../components/PageHeader";
import PalaceInThePressTable from "../components/NewsroomTable/PalaceInThePressTable";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import PressReleasesTable from "../components/NewsroomTable/PressReleasesTable";

const selectedStyles = { bg: "yellowLight", color: "greenDark" };

function Newsroom() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onTabChange = (index: number) => {
    if (index === 0) {
      navigate({
        pathname: "/newsroom",
        search: "?tab=palace-in-the-press",
      });
    } else {
      navigate({
        pathname: "/newsroom",
        search: "?tab=press-releases",
      });
    }
  };

  return (
    <Box bg="blackLight" flex={1}>
      <PageHeader title="Newsroom" />
      <Container maxW={"7xl"} mt="6">
        <Tabs
          variant="enclosed"
          isFitted
          borderColor={"yellowLight"}
          onChange={onTabChange}
          defaultIndex={searchParams.get("tab") !== "press-releases" ? 0 : 1}
        >
          <TabList>
            <Tab _selected={selectedStyles} color="greenExtraLight1">
              <HStack justify={"space-between"} flex={1}>
                <Text color="greenDark">Palace in the press</Text>
                <CreateButton query="palace-in-the-press" />
              </HStack>
            </Tab>
            <Tab _selected={selectedStyles} color="greenExtraLight1">
              <HStack justify={"space-between"} flex={1}>
                <Text color="greenDark">Press releases</Text>
                <CreateButton query="press-releases" />
              </HStack>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0">
              <PalaceInThePressTable />
            </TabPanel>
            <TabPanel px="0">
                <PressReleasesTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
}
export default withAuthenticationRequired(Newsroom, {
  onRedirecting: () => <Loading />,
  returnTo: "/",
});

const CreateButton = ({ query }: { query: string }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate({
      pathname: "/newsroom/create",
      search: `?type=${query}`,
    });
  };

  return (
    <IconButton
      as="span"
      aria-label="Create article"
      variant={"ghost"}
      _hover={{ bg: "yellowMid" }}
      icon={<MdPostAdd fontSize={"1.4rem"} />}
      color="greenDark"
      alignSelf={"flex-end"}
      onClick={onClick}
    />
  );
};
