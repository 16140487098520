import { GridItem, Heading } from "@chakra-ui/react";
import { useScroll } from "../../utils/useScroll";

const TableHeader = ({ columns }: { columns: Array<string> }) => {
  const scroll = useScroll();

  return (
    <>
      {columns.map((col: string) => (
        <GridItem
          key={col}
          px="2"
          py="4"
          position={"sticky"}
          top={scroll?.y >= 64 ? "72px" : "0px"}
          transition="all 0s ease-out 0.3s"
          bg={scroll?.y >= 64 ? "blackLight" : "transparent"}
          zIndex={1}
        >
          <Heading
            color="greenMid"
            textTransform={"uppercase"}
            as="h3"
            fontSize="sm"
            // whiteSpace={"nowrap"}
            textAlign="left"
          >
            {col}
          </Heading>
        </GridItem>
      ))}
    </>
  );
};

export default TableHeader;
